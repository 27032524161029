<template>
  <div class="container mt-7 mb-7">
    <div class="text-center mt-4 mb-7" v-if="loading">
      <spinner></spinner>
    </div>
    <div class="row" v-else>
      <div class="card col col-md-8 mx-auto px-4 py-3 pt-4">
        <div v-if="success">
          <div v-if="res.code == 'resend_required'">
            <h1 class="display-4 text-info">
              This verification link has been expired.
            </h1>
            <base-button
              style="text-transform:none;"
              @click="sendVerification()"
              type="primary"
              class="btn-round"
              >Send New Verification Link</base-button
            >
            <base-alert class="mt-2" v-if="verification_sent">
              New verification link has been sent to
              <strong>{{ res.email }}</strong></base-alert
            >
            <base-alert
              class="mt-3"
              v-if="resend_error"
              type="danger"
              dismissible
            >
              An error occurred. Could not send verification email.</base-alert
            >
          </div>
          <div class="mt-3 mb-5">
            <h1 v-if="res.code == 'verified'" class="display-4 text-success">
              Account successfully verified
            </h1>
            <h1
              v-else-if="res.code == 'already_verified'"
              class="display-4 text-success"
            >
              Your account has been already verified
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner";
export default {
  components: {
    Spinner
  },
  metaInfo: () => ({
    title: "Verify Email"
  }),
  data: () => ({
    loading: true,
    success: false,
    invalid_token: false,
    res: null,
    verification_sent: false,
    resend_error: false,
    timer: 5
  }),
  mounted() {
    this.$axios
      .get("verify-email/" + this.$route.params.token)
      .then(res => {
        this.res = res.data;
        this.loading = false;
        this.success = true;
        if (
          this.res.code == "verified" ||
          this.res.code == "already_verified"
        ) {
          this.$store.commit("login_message", "Account successfully verified");
          this.$router.push("/login");
        }
      })
      .catch(err => {
        this.loading = false;
        this.success = false;
        if (err.response.data.code == "invalid_token") {
          this.$router.push("/login");
        }
      });
  },
  methods: {
    sendVerification() {
      this.$axios
        .post("send-verification/", { email: this.res.email })
        .then(res => {
          if (res.data.code == "verification_sent") {
            this.verification_sent = true;
          }
        })
        .catch(err => {
          if (err.response) {
            this.resend_error = true;
          }
        });
    }
  }
};
</script>

<style></style>
